.dynamicbg {
  position: absolute;
  opacity: 0;
  background: #fe0;
  border-radius: 26px;
  transition: all 0.6s;
  z-index: -1;
}

.menu_header{
  display: flex;
  flex-direction: row;

  .menu__link{
    padding: 15px 30px;
    font: 800 13px/20px 'Rounded Mplus 1c';
    color: $black-text;
  }
}


@media (max-width: 480px) {
  .dynamicbg {
    display: none;
  }

  .menu_header {
    .menu__link {
      padding: 10px 20px;
    }
  }
}

@media (max-width: 340px) {
  .menu_header {
    .menu__link {
      padding: 10px 15px;
    }
  }
}