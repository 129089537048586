.svg{
  width: 100%;
  height: 100%;

  svg{
    max-width: 100%;
    height: 100%;
  }
}

.svg--icon{
  @include transition(1);
}

.svg--vk:hover{
  color: #f55;
}
/*
.svg--fb,
.svg--yt,
.svg--tw,
.svg--tg{

}*/
