.list{

}


.list_def{
  list-style: none;

  .list__item{
    position: relative;
    padding-left: 21px;
    font: bold 14px/26px Roboto;

    &:before{
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      top: 9px;
      left: 0;
      background: $purple;
    }
  }
}