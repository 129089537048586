* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

script,
title,
style {
  display: none;
}

html {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: $black-text;
  height: 100%;
}

body {
  min-height: 100%;
  background: $light-yellow;
}

a {
  color: inherit;
  text-decoration: none;
}

input, textarea{
  background: transparent;
  border: none;
  outline: none;
  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #fff;
  }
}

::selection {
  background: $light-purple; /* Safari */
}
::-moz-selection {
  background: $light-purple; /* Firefox */
}


.g-hidden{
  display: none;
}

.g-purple-text{
  color: $purple-text;
}

.g-red-text{
  color: $red-text;
}

.g-gray-text{
  color: $gray-text;
}

.container{
  $max-width: $innerWidth;
  $padding: 20px;
  width: 100%;
  max-width: ($max-width+$padding*2);
  margin: 0 auto;
  padding-left: $padding;
  padding-right: $padding;
}


