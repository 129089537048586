.btn {
  $height: 40px;

  display: inline-block;
  position: relative;
  justify-content: center;
  align-items: center;
  height: $height;
  border-radius: 100px;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  outline: none;
  text-align: center;
  transition: all 0.2s;
  font: 500 13px/#{$height} Roboto;
  min-width: 150px;
}



.btn_primary{
  $background: $purple;
  $color: #F3EEF9;

  position: relative;
  margin-bottom: 7px;
  z-index: 1;

  .btn__inner{
    display: block;
    background: $background;
    color: $color;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.2s;
  }

  .btn__inner:before{
    $width: 110px;
    content: '';
    width: $width;
    height: 26px;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -$width/2;
    box-shadow: 0 7px 20px #340665;
    border-radius: 100px;
    z-index: -1;
  }

  &:hover .btn__inner{
    background: #7B00FF;
  }

  &:active .btn__inner{
    background: #3B007A;
    color: #B593DE;
  }
}