.section{

}


/**********************************************************************************
 * ---------------Типовые секции с тайтлами и претайтлами и т.п.----------------- *
 *********************************************************************************/
.section_def {
  padding-bottom: 60px;

  /*------------------------------[ pretitle ]--------------------------------*/
  .section__pretitle {
    font: bold 11px/16px "Rounded Mplus 1c";
    text-transform: uppercase;
    color: #757575;
    margin-bottom: 15px;
  }

  .section__pretitle_red {
    color: #FF003B;
  }

  /*------------------------------[ title ]--------------------------------*/
  .section__title {
    font: 900 28px/36px "Rounded Mplus 1c";
    color: #191919;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .section__title_h1{
    font: 900 32px/36px 'Rounded Mplus 1c';
  }

  /*------------------------------[ list ]--------------------------------*/
  .section__list-title{
    font: 900 18px/24px 'Rounded Mplus 1c';
    margin-bottom: 15px;
  }

  /*------------------------------[ text ]--------------------------------*/
  .section__text{
    font: normal 14px/24px Roboto;
    margin-bottom: 30px;
  }
}


.section_half{
  .section__inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}


.section_half_swap{
  .section__inner{
    flex-direction: row-reverse;
  }
}


/**********************************************************************************
 * ---------------------------Специфические стили секций------------------------- *
 *********************************************************************************/


/*****************************************************************
 * Секция "Проектируем структуру и пишем тексты."
 ****************************************************************/
.section_id_first{
  padding-bottom: 120px;

  .section__info{
    max-width: 500px;
  }

  .section__button{
    margin-top: 35px;
  }

  .section__blockquote{
    max-width: 361px;
  }
}


/*****************************************************************
 * Секция "Проектируем структуру и пишем тексты."
 ****************************************************************/
.section_id_structure_and_texts{
  padding-bottom: 151px;

  .section__info{
    max-width: 506px;
    margin-right: 64px;
  }
}


/*****************************************************************
 * Секция "Создаём визуальную ассоциацию сайта и компании"
 ****************************************************************/
.section_id_prepare-design-layout{
  padding-bottom: 118px;

  .section__info{
    max-width: 548px;
  }

  .section__pic{
    margin-right: 100px;
  }
}


/*****************************************************************
 * Секция "Используем WordPress"
 ****************************************************************/
.section_id_wordpress{
  padding-bottom: 142px;

  .section__info{
    max-width: 570px;
  }
}


/*****************************************************************
 * Секция "Настраиваем рекламу и привлекаем покупателей."
 ****************************************************************/
.section_id_direct{
  padding-bottom: 102px;

  .section__info{
    max-width: 507px;
  }
}


/*****************************************************************
 * Секция "Чем с нами удобно?"
 ****************************************************************/
.section_id_advantages-slider{
  background: linear-gradient(to bottom, $light-yellow, #fff);
  padding-bottom: 125px;

  .section__info{
    max-width: 548px;
  }

  .section__title{
    margin-top: 64px;
    margin-bottom: 60px;
  }

  .section__slider{
    height: 350px;
    overflow: hidden;
  }
}


/*****************************************************************
 * Секция "Сколько стоит?"
 ****************************************************************/
.section_id_price{
  background: #fff;
  padding-bottom: 103px;

  .section__inner{
    justify-content: flex-start;
  }

  .section__info{
    width: 50%;
  }

  .section__price-card{
    padding-left: 40px;
    width: 50%;
  }

  .section__blockquote{
    padding: 0 60px 88px 142px;
    background: url(../images/man-face.svg) left bottom no-repeat;
  }
}


/*****************************************************************
 * Секция "Вы можете заказать свой лендинг прямо сейчас!"
 ****************************************************************/
.section_id_order-call{
  background: #fff;
  padding-bottom: 120px;

  .section__title{
    font: 900 28px/36px 'Rounded Mplus 1c';
  }

  .section__inner{
    padding: 0 100px;
    position: relative;
  }

  .section__box{
    background: #FFEFE1;
    text-align: center;
    padding: 73px 251px;
    border-radius: 20px;

    &:before{
      content: '';
      background: url(../images/man_1.svg) no-repeat;
      width: 370px;
      height: 333px;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &:after{
      content: '';
      background: url(../images/woman_2.svg);
      width: 370px;
      height: 333px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

/*****************************************************************
 * Секция "Оцените наши работы"
 ****************************************************************/
.section_id_portfolio{
  text-align: center;
  background: #fff;
  padding-bottom: 120px;


  .section__sliders{
    position: relative;
    padding-bottom: 50px;
    padding-right: 80px;
  }

  .section__slider-mobile{
    position: absolute;
    right: 100px;
    bottom: 0;
    z-index: 10;
  }

  .section__sliders-pagination{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    width: 50px;
  }
}


/*****************************************************************
 * Секция "Форма заказа"
 ****************************************************************/

.section_id_feedback{
  background: linear-gradient(to bottom, #fff 0%, $light-yellow 350px);
  padding-bottom: 120px;

  .section__title{
    text-transform: none;
    font: 900 60px/70px Roboto;
    margin-bottom: 60px;
  }

  .section__form{
    max-width: 870px;
  }
}

/*****************************************************************
 * Секция "Контакты"
 ****************************************************************/

.section_id_contacts{
  padding-bottom: 60px;

  .section__inner{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .section__col{
    max-width: 237px;
  }

  .section__city{
    font: 900 20px/28px 'Rounded Mplus 1c';
    margin-bottom: 30px;
  }


  .section__address{
    font: normal 14px/18px Roboto;
    margin-bottom: 15px;
    min-height: 35px;
  }

  .section__phone{
    font: bold 14px/28px Roboto;
  }
}

/*****************************************************************
 * Секция "Карта"
 ****************************************************************/
.section_map{
  overflow: hidden;
}



/*****************************************************************
 * Responsive
 ****************************************************************/

@media (max-width: 1130px) {
  .section_half{
    .section__inner{
      flex-direction: column;
    }

    .section__info{
      width: 100%;
      max-width: 570px;
      padding-bottom: 50px;
      margin-right: 0;
    }

    .section__pic{
      width: 100%;
      margin-right: 0;
      display: flex;
      justify-content: center;


    }
  }

  .section_def{
    padding-bottom: 100px;
  }


  .section_id_advantages-slider{
    .section__info{
      padding-bottom: 0;
    }
  }


  .section_id_price{
    .section__price-card{
      display: flex;
      justify-content: center;
      padding-left: 0;
      width: 100%;
    }
  }


  .section_id_order-call{
    .section__inner{
      display: flex;
      justify-content: center;
      padding: 0 20px;
    }


    .section__box{
      padding: 60px;
      max-width: 470px;

      &:before,
      &:after{
        display: none;
      }
    }
  }

  .section_id_feedback{
    .section__title{
      font: 900 50px/58px Roboto;
    }
  }

}


@media (max-width: 850px) {
  .section_id_contacts{
    .section__inner{
      flex-direction: column;
    }

    .section__col{
      margin-bottom: 40px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .section_def{
    padding-bottom: 80px;
  }

  .section_id_feedback{
    .section__title{
      font: 900 40px/47px Roboto;
      margin-bottom: 50px;
    }
  }
}


@media (max-width: 600px) {
  .section_id_feedback{
    .section__title{
      font: 900 30px/37px Roboto;
      margin-bottom: 35px;
    }
  }

  .section_def{
    padding-bottom: 60px;
  }

  .section_id_advantages-slider .section__title{
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .section_def {
    .section__title {
        font: 900 22px/30px "Rounded Mplus 1c";
    }
  }

  .section_id_feedback{
    .section__title{
      font: 900 20px/25px Roboto;
      margin-bottom: 30px;
    }
  }

  .section_def{
    padding-bottom: 50px;
  }

  .section_id_price{
    .section__blockquote{
      padding-left: 0;
      padding-bottom: 0;
      padding-right: 0;
      background: transparent;
    }
  }

  .section_id_advantages-slider{
    .section__slider{
      height: 470px;
    }
  }
}