.blockquote {

}


.blockquote_bubble{
  position: relative;
  background: $dark-gray;
  border-radius: 10px 10px 10px 0;
  padding: 20px 30px;
  color: #F5F5F5;
  font: 500 italic 14px/18px Roboto;
  width: calc(100% - 20px);
  margin-bottom: 20px;
  margin-left: 20px;
  opacity: 0;
  transform: scale(0.5) rotate(10deg);
  transition: transform 0.6s 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s 0.1s;

  &.showed{
    opacity: 1;
    transform: scale(1) rotate(0);
  }

  .blockquote__label{
    color: #A2A2A2;
    margin-bottom: 12px;
  }

  &:before{
    content: '';
    background: url(../images/quote.svg) no-repeat left top;
    width: 29px;
    height: 22px;
    position: absolute;
    left: -20px;
    top: 19px;
  }

  &:after{
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    border-top: $dark-gray solid 10px;
    border-left: $dark-gray solid 12px;
    border-right: transparent solid 12px;
    border-bottom: transparent solid 10px;
  }
}


@media (max-width: 480px) {
  .blockquote_bubble{
    margin-left: 0;
  }
}



/*
.swiper-slide-active .blockquote_bubble,
.swiper-slide-prev .blockquote_bubble,
.section_id_first.swiper-slide-next .blockquote_bubble{
  opacity: 1;
  transform: scale(1) rotate(0);
}
*/
