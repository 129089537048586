.header {
  padding-top: 20px;
  margin-bottom: 47px;
}

.header__inner{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__logo{
  margin-right: 90px;
  flex-shrink: 0;
}


@media (max-width: 670px) {
  .header__inner{
    flex-direction: column;
  }

  .header__logo{
    margin-right: 0;
    margin-bottom: 30px;
  }
}