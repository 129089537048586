.slider{
  height: 100%;
  position: relative;

  .slider__inner{
    height: 100%;
  }

  .swiper-pagination{}
  .swiper-pagination-bullet{
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: $light-purple;
    transition: all 0.2s;
    margin-bottom: 16px;
    opacity: 1;
    outline: none;

    &:before{
      content: '';
      position: absolute;
      border: 1px solid $purple;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      left: -4px;
      top: -4px;
      opacity: 0;
      transform: scale(0);
      transition: all 0.5s cubic-bezier(.5,0,.33,2);
    }

    &:hover{
      background: $purple;
    }
  }

  .swiper-pagination-bullet-active{
    background: $purple;

    &:before{
      opacity: 1;
      transform: scale(1);
    }
  }
}

.slider_vertical{
  .swiper-pagination{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .swiper-pagination-bullet{
    display: block;
  }
}

.slider_advantages{
  padding-right: 83px;


  .slider__slide{
    height: 350px;
  }

  .slider__title{
    font: 900 26px/34px 'Rounded Mplus 1c';
    margin-bottom: 15px;
  }

  .slider__text{
    font: normal 14px/24px Roboto;
  }

  .slider__img{
    position: relative;
    z-index: 2;
  }

  .slider__icon{
    width: 80px;
    height: 80px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #f5f5f5;
      border-radius: 25px;
      transform: scale(0);
      transition: all 0.6s 0.2s;
      z-index: 1;
    }
  }

  .slider__icon_green:before{
    background: #E9F9DD;
  }
  .slider__icon_yellow:before{
    background: #FFF6E2;
  }
  .slider__icon_red:before{
    background: #FEF1F4;
  }
  .slider__icon_blue:before{
    background: #ECF1FC;
  }
  .slider__icon_purple:before{
    background: #F5ECFE;
  }


  .swiper-slide-active .slider__icon:before{
    transform: scale(1);
  }


  @media (max-width: 480px) {
    padding-right: 60px;
  }
}


.slider_portfolio{
  background: $dark-gray;
  border-radius: 12px;
  overflow: hidden;
  padding: 15px;

  .slider__inner{
    border-radius: 12px;
    overflow: hidden;
  }
}

.slider_portfolio_desktop{
  max-width: 970px;
  height: 550px;
  border: 1px solid #B7B7B7;
}

.slider_portfolio_mobile{
  width: 270px;
  height: 480px;
  padding: 15px 8px;
  border: 1px solid #3D3D3D;
}