*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:before,
  &:after{
    box-sizing: border-box;
  }
}

a{
  color: inherit;
  text-decoration: none;

  &:hover{
    text-decoration: none;
  }
}

img{
  max-width: 100%;
}

/*ul,
ol{
  list-style: none;
}*/

button,
[type="button"],
[type="submit"]{
  background: transparent;
  border: none;
  font-size: inherit;
  cursor: pointer;
}

[type="text"],
[type="tel"],
[type="number"],
[type="email"],
[type="password"],
textarea,
select{
  border: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

svg{
  overflow: visible;
  transform: translateZ(0);
}

picture{
  display: inline-block;
  line-height: 0;
}

picture source{
  line-height: 0;
}

cite{
  font-style: inherit;
}

[contenteditable][data-placeholder]:empty::before {
  content: attr(data-placeholder);
  cursor: text;
  color: rgba(0,0,0,0.3);
}

[contenteditable][data-placeholder]:empty:focus::before {
  content: "";
}