.text-input{
  width: 100%;
  height: 40px;
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid #EFE6DB;
  border-radius: 6px;
  padding: 0 20px;
  font: 300 14px/24px Roboto;

  &:focus{
    border-color: #E6D2B9;
  }

  &::-webkit-input-placeholder { /* Chrome */
    color: $black-text;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $black-text;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $black-text;
    opacity: 1;
  }
  &:-moz-placeholder { /* Firefox 4 - 18 */
    color: $black-text;
    opacity: 1;
  }

}

textarea.text-input{
  padding: 12px 20px;
  min-height: 120px;
  resize: none;
}

.form__field_error .text-input{
  border-color: #F194B9;
  color: $red;
}




