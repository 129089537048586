.form{
  .form__label{
    display: block;
    font: bold 12px/24px Roboto;
    color: $black-text;
    margin-bottom: 4px;
  }

  .form__input-wrap{
    display: block;
  }

  .form__row{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-left: -30px;
  }

  .form__field{
    display: block;
    flex: 1;
    padding-left: 30px;
  }

  .form__field_error{
    .form__label{
      color: $red-text;
    }
  }

  .form__success{
    opacity: 0;
    transition: opacity 0.2s;
    position: relative;

    &.active{
      opacity: 1;
    }
  }

  .form__success-msg{
    display: inline-block;
    position: relative;
  }

  .form__success-text{
    padding: 12px 20px;
    color: #50a02d;
    position: relative;
    z-index: 2;
  }

  .form__success-bg{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #E9F9DD;
    border-radius: 25px;
    width: 50px;
    transition: all 0.9s 0.1s;
    z-index: 1;
  }

  .form__success.active  .form__success-bg{
    width: 100%;
  }


  @media (max-width: 850px) {
    .form__row{
      flex-direction: column;
      margin-left: 0;
    }
    .form__field{
      padding-left: 0;
    }

    .form__label{
      margin-top: 20px;

      &:first-child{
        margin-top: 0;
      }
    }
  }
}