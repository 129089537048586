@charset "UTF-8";
/****************************************************************
 * Libs
 ****************************************************************/
/****************************************************************
 * Vars Functions and Mixins
 ****************************************************************/
/*--------------------------[ Colors ]-----------------------------*/
/*------------------------------[ Text Colors ]--------------------------------*/
/*--------------------------[ Sizes ]-----------------------------*/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

@keyframes button_animation {
  to {
    transform: scale(1);
  }
}

/****************************************************************
 * Reset & Base Styles
 ****************************************************************/
/*****************************************************************
 * Rounded Mplus 1c
 ****************************************************************/
@font-face {
  font-family: 'Rounded Mplus 1c';
  src: url("../fonts/RoundedMplus1c-Medium.eot");
  src: local("Rounded Mplus 1c Medium"), local("RoundedMplus1c-Medium"), url("../fonts/RoundedMplus1c-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/RoundedMplus1c-Medium.woff2") format("woff2"), url("../fonts/RoundedMplus1c-Medium.woff") format("woff"), url("../fonts/RoundedMplus1c-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rounded Mplus 1c';
  src: url("../fonts/RoundedMplus1c-ExtraBold.eot");
  src: local("Rounded Mplus 1c ExtraBold"), local("RoundedMplus1c-ExtraBold"), url("../fonts/RoundedMplus1c-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RoundedMplus1c-ExtraBold.woff2") format("woff2"), url("../fonts/RoundedMplus1c-ExtraBold.woff") format("woff"), url("../fonts/RoundedMplus1c-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/*@font-face {
  font-family: 'Rounded Mplus 1c';
  src: url('../fonts/RoundedMplus1c-Thin.eot');
  src: local('Rounded Mplus 1c Thin'), local('RoundedMplus1c-Thin'),
  url('../fonts/RoundedMplus1c-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RoundedMplus1c-Thin.woff2') format('woff2'),
  url('../fonts/RoundedMplus1c-Thin.woff') format('woff'),
  url('../fonts/RoundedMplus1c-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}*/
@font-face {
  font-family: 'Rounded Mplus 1c';
  src: url("../fonts/RoundedMplus1c-Black.eot");
  src: local("Rounded Mplus 1c Black"), local("RoundedMplus1c-Black"), url("../fonts/RoundedMplus1c-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/RoundedMplus1c-Black.woff2") format("woff2"), url("../fonts/RoundedMplus1c-Black.woff") format("woff"), url("../fonts/RoundedMplus1c-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/*@font-face {
  font-family: 'Rounded Mplus 1c';
  src: url('../fonts/RoundedMplus1c-Light.eot');
  src: local('Rounded Mplus 1c Light'), local('RoundedMplus1c-Light'),
  url('../fonts/RoundedMplus1c-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RoundedMplus1c-Light.woff2') format('woff2'),
  url('../fonts/RoundedMplus1c-Light.woff') format('woff'),
  url('../fonts/RoundedMplus1c-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rounded Mplus 1c';
  src: url('../fonts/RoundedMplus1c-Regular.eot');
  src: local('Rounded Mplus 1c'), local('RoundedMplus1c-Regular'),
  url('../fonts/RoundedMplus1c-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RoundedMplus1c-Regular.woff2') format('woff2'),
  url('../fonts/RoundedMplus1c-Regular.woff') format('woff'),
  url('../fonts/RoundedMplus1c-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}*/
@font-face {
  font-family: 'Rounded Mplus 1c';
  src: url("../fonts/RoundedMplus1c-Bold.eot");
  src: local("Rounded Mplus 1c Bold"), local("RoundedMplus1c-Bold"), url("../fonts/RoundedMplus1c-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/RoundedMplus1c-Bold.woff2") format("woff2"), url("../fonts/RoundedMplus1c-Bold.woff") format("woff"), url("../fonts/RoundedMplus1c-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/*****************************************************************
 * Roboto
 ****************************************************************/
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Bold.eot");
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Regular.eot");
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Medium.woff') format('woff'),
  url('../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Light.eot');
  src: local('Roboto Light'), local('Roboto-Light'),
  url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Light.woff') format('woff'),
  url('../fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}*/
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-MediumItalic.eot");
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url("../fonts/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-MediumItalic.woff") format("woff"), url("../fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

/*
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-LightItalic.eot');
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
  url('../fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-LightItalic.woff') format('woff'),
  url('../fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-BlackItalic.eot');
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
  url('../fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-BlackItalic.woff') format('woff'),
  url('../fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Thin.eot');
  src: local('Roboto Thin'), local('Roboto-Thin'),
  url('../fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Thin.woff') format('woff'),
  url('../fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}*/
/*@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Italic.eot');
  src: local('Roboto Italic'), local('Roboto-Italic'),
  url('../fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Italic.woff') format('woff'),
  url('../fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}*/
/*@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-BoldItalic.eot');
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
  url('../fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-BoldItalic.woff') format('woff'),
  url('../fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}*/
/*@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-ThinItalic.eot');
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
  url('../fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-ThinItalic.woff') format('woff'),
  url('../fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
*/
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Black.eot");
  src: local("Roboto Black"), local("Roboto-Black"), url("../fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Black.woff") format("woff"), url("../fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:before, *:after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

/*ul,
ol{
  list-style: none;
}*/
button,
[type="button"],
[type="submit"] {
  background: transparent;
  border: none;
  font-size: inherit;
  cursor: pointer;
}

[type="text"],
[type="tel"],
[type="number"],
[type="email"],
[type="password"],
textarea,
select {
  border: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

svg {
  overflow: visible;
  transform: translateZ(0);
}

picture {
  display: inline-block;
  line-height: 0;
}

picture source {
  line-height: 0;
}

cite {
  font-style: inherit;
}

[contenteditable][data-placeholder]:empty::before {
  content: attr(data-placeholder);
  cursor: text;
  color: rgba(0, 0, 0, 0.3);
}

[contenteditable][data-placeholder]:empty:focus::before {
  content: "";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

script,
title,
style {
  display: none;
}

html {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #191919;
  height: 100%;
}

body {
  min-height: 100%;
  background: #FFFAF4;
}

a {
  color: inherit;
  text-decoration: none;
}

input, textarea {
  background: transparent;
  border: none;
  outline: none;
}

input:-webkit-autofill, textarea:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff;
}

::selection {
  background: #E6D6F8;
  /* Safari */
}

::-moz-selection {
  background: #E6D6F8;
  /* Firefox */
}

.g-hidden {
  display: none;
}

.g-purple-text {
  color: #5000A6;
}

.g-red-text {
  color: #FF003B;
}

.g-gray-text {
  color: #757575;
}

.container {
  width: 100%;
  max-width: 1210px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

/****************************************************************
 * Blocks
 ****************************************************************/
.btn {
  display: inline-block;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 100px;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  outline: none;
  text-align: center;
  transition: all 0.2s;
  font: 500 13px/40px Roboto;
  min-width: 150px;
}

.btn_primary {
  position: relative;
  margin-bottom: 7px;
  z-index: 1;
}

.btn_primary .btn__inner {
  display: block;
  background: #5000A6;
  color: #F3EEF9;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s;
}

.btn_primary .btn__inner:before {
  content: '';
  width: 110px;
  height: 26px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -55px;
  box-shadow: 0 7px 20px #340665;
  border-radius: 100px;
  z-index: -1;
}

.btn_primary:hover .btn__inner {
  background: #7B00FF;
}

.btn_primary:active .btn__inner {
  background: #3B007A;
  color: #B593DE;
}

.list_def {
  list-style: none;
}

.list_def .list__item {
  position: relative;
  padding-left: 21px;
  font: bold 14px/26px Roboto;
}

.list_def .list__item:before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 9px;
  left: 0;
  background: #5000A6;
}

.blockquote_bubble {
  position: relative;
  background: #191919;
  border-radius: 10px 10px 10px 0;
  padding: 20px 30px;
  color: #F5F5F5;
  font: 500 italic 14px/18px Roboto;
  width: calc(100% - 20px);
  margin-bottom: 20px;
  margin-left: 20px;
  opacity: 0;
  transform: scale(0.5) rotate(10deg);
  transition: transform 0.6s 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s 0.1s;
}

.blockquote_bubble.showed {
  opacity: 1;
  transform: scale(1) rotate(0);
}

.blockquote_bubble .blockquote__label {
  color: #A2A2A2;
  margin-bottom: 12px;
}

.blockquote_bubble:before {
  content: '';
  background: url(../images/quote.svg) no-repeat left top;
  width: 29px;
  height: 22px;
  position: absolute;
  left: -20px;
  top: 19px;
}

.blockquote_bubble:after {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  border-top: #191919 solid 10px;
  border-left: #191919 solid 12px;
  border-right: transparent solid 12px;
  border-bottom: transparent solid 10px;
}

@media (max-width: 480px) {
  .blockquote_bubble {
    margin-left: 0;
  }
}

/*
.swiper-slide-active .blockquote_bubble,
.swiper-slide-prev .blockquote_bubble,
.section_id_first.swiper-slide-next .blockquote_bubble{
  opacity: 1;
  transform: scale(1) rotate(0);
}
*/
.dynamicbg {
  position: absolute;
  opacity: 0;
  background: #fe0;
  border-radius: 26px;
  transition: all 0.6s;
  z-index: -1;
}

.menu_header {
  display: flex;
  flex-direction: row;
}

.menu_header .menu__link {
  padding: 15px 30px;
  font: 800 13px/20px 'Rounded Mplus 1c';
  color: #191919;
}

@media (max-width: 480px) {
  .dynamicbg {
    display: none;
  }
  .menu_header .menu__link {
    padding: 10px 20px;
  }
}

@media (max-width: 340px) {
  .menu_header .menu__link {
    padding: 10px 15px;
  }
}

.price-card {
  padding: 90px 60px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 100%;
  max-width: 470px;
  font: normal 14px/28px Roboto;
  background: #FFFAF4;
}

.price-card__title {
  font: 900 20px/28px 'Rounded Mplus 1c';
  margin-bottom: 30px;
}

.price-card__price {
  font: 900 40px/40px 'Rounded Mplus 1c';
  margin-bottom: 5px;
}

.price-card__time {
  margin-top: 60px;
}

.price-card__time-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.price-card__time-key {
  font: normal 14px/28px Roboto;
  text-align: left;
}

.price-card__time-value {
  font: bold 14px/28px Roboto;
  text-align: right;
  margin-left: 20px;
}

.price-card__time-row_total {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #DED9D4;
}

.price-card__time-row_total .price-card__time-key {
  font-weight: bold;
  color: #FF003B;
}

.price-card__adstime {
  margin-top: 30px;
  background: #FFEFE1 no-repeat padding-box;
  border-radius: 10px;
  padding: 15px 20px;
}

.price-card__adstime-title {
  font: 900 16px/28px 'Rounded Mplus 1c';
  margin-bottom: 10px;
}

.price-card__adstime-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.price-card__adstime-label {
  font: normal 14px/18px Roboto;
  text-align: left;
}

.price-card__adstime-key {
  font: normal 14px/18px Roboto;
  text-align: left;
}

.price-card__adstime-value {
  font: bold 14px/18px Roboto;
  text-align: right;
  margin-left: 20px;
}

@media (max-width: 480px) {
  .price-card {
    padding: 40px 30px;
  }
  .price-card__price {
    font: 900 28px/28px 'Rounded Mplus 1c';
  }
}

.text-input {
  width: 100%;
  height: 40px;
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid #EFE6DB;
  border-radius: 6px;
  padding: 0 20px;
  font: 300 14px/24px Roboto;
}

.text-input:focus {
  border-color: #E6D2B9;
}

.text-input::-webkit-input-placeholder {
  /* Chrome */
  color: #191919;
}

.text-input:-ms-input-placeholder {
  /* IE 10+ */
  color: #191919;
}

.text-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #191919;
  opacity: 1;
}

.text-input:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #191919;
  opacity: 1;
}

textarea.text-input {
  padding: 12px 20px;
  min-height: 120px;
  resize: none;
}

.form__field_error .text-input {
  border-color: #F194B9;
  color: #FF003B;
}

.check-input {
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
}

.check-input__input {
  position: absolute;
  left: -100px;
  width: 0;
  height: 0;
  opacity: 0;
}

.check-input__label {
  font: bold 14px/24px Roboto;
  vertical-align: middle;
}

.check-input__label:before {
  content: '';
  display: inline-flex;
  width: 20px;
  height: 20px;
  border: 1px solid #EFE6DB;
  background: #fff;
  margin-right: 15px;
  align-items: center;
  vertical-align: middle;
}

.check-input__label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 1px;
  width: 20px;
  height: 20px;
  background: url(../images/check.svg) center center no-repeat;
  background-size: 14px 12px;
  opacity: 0;
  transform: rotate(45deg) scale(0.7);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.check-input__input:checked + .check-input__label:after {
  opacity: 1;
  transform: rotate(0) scale(1);
}

.form__field_error .check-input__label:before {
  border-color: #F194B9;
}

.form .form__label {
  display: block;
  font: bold 12px/24px Roboto;
  color: #191919;
  margin-bottom: 4px;
}

.form .form__input-wrap {
  display: block;
}

.form .form__row {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  margin-left: -30px;
}

.form .form__field {
  display: block;
  flex: 1;
  padding-left: 30px;
}

.form .form__field_error .form__label {
  color: #FF003B;
}

.form .form__success {
  opacity: 0;
  transition: opacity 0.2s;
  position: relative;
}

.form .form__success.active {
  opacity: 1;
}

.form .form__success-msg {
  display: inline-block;
  position: relative;
}

.form .form__success-text {
  padding: 12px 20px;
  color: #50a02d;
  position: relative;
  z-index: 2;
}

.form .form__success-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #E9F9DD;
  border-radius: 25px;
  width: 50px;
  transition: all 0.9s 0.1s;
  z-index: 1;
}

.form .form__success.active .form__success-bg {
  width: 100%;
}

@media (max-width: 850px) {
  .form .form__row {
    flex-direction: column;
    margin-left: 0;
  }
  .form .form__field {
    padding-left: 0;
  }
  .form .form__label {
    margin-top: 20px;
  }
  .form .form__label:first-child {
    margin-top: 0;
  }
}

.slider {
  height: 100%;
  position: relative;
}

.slider .slider__inner {
  height: 100%;
}

.slider .swiper-pagination-bullet {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #E6D6F8;
  transition: all 0.2s;
  margin-bottom: 16px;
  opacity: 1;
  outline: none;
}

.slider .swiper-pagination-bullet:before {
  content: '';
  position: absolute;
  border: 1px solid #5000A6;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  left: -4px;
  top: -4px;
  opacity: 0;
  transform: scale(0);
  transition: all 0.5s cubic-bezier(0.5, 0, 0.33, 2);
}

.slider .swiper-pagination-bullet:hover {
  background: #5000A6;
}

.slider .swiper-pagination-bullet-active {
  background: #5000A6;
}

.slider .swiper-pagination-bullet-active:before {
  opacity: 1;
  transform: scale(1);
}

.slider_vertical .swiper-pagination {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.slider_vertical .swiper-pagination-bullet {
  display: block;
}

.slider_advantages {
  padding-right: 83px;
}

.slider_advantages .slider__slide {
  height: 350px;
}

.slider_advantages .slider__title {
  font: 900 26px/34px 'Rounded Mplus 1c';
  margin-bottom: 15px;
}

.slider_advantages .slider__text {
  font: normal 14px/24px Roboto;
}

.slider_advantages .slider__img {
  position: relative;
  z-index: 2;
}

.slider_advantages .slider__icon {
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slider_advantages .slider__icon:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 25px;
  transform: scale(0);
  transition: all 0.6s 0.2s;
  z-index: 1;
}

.slider_advantages .slider__icon_green:before {
  background: #E9F9DD;
}

.slider_advantages .slider__icon_yellow:before {
  background: #FFF6E2;
}

.slider_advantages .slider__icon_red:before {
  background: #FEF1F4;
}

.slider_advantages .slider__icon_blue:before {
  background: #ECF1FC;
}

.slider_advantages .slider__icon_purple:before {
  background: #F5ECFE;
}

.slider_advantages .swiper-slide-active .slider__icon:before {
  transform: scale(1);
}

@media (max-width: 480px) {
  .slider_advantages {
    padding-right: 60px;
  }
}

.slider_portfolio {
  background: #191919;
  border-radius: 12px;
  overflow: hidden;
  padding: 15px;
}

.slider_portfolio .slider__inner {
  border-radius: 12px;
  overflow: hidden;
}

.slider_portfolio_desktop {
  max-width: 970px;
  height: 550px;
  border: 1px solid #B7B7B7;
}

.slider_portfolio_mobile {
  width: 270px;
  height: 480px;
  padding: 15px 8px;
  border: 1px solid #3D3D3D;
}

.svg {
  width: 100%;
  height: 100%;
}

.svg svg {
  max-width: 100%;
  height: 100%;
}

.svg--icon {
  transition: all ease-in 0.1s;
}

.svg--vk:hover {
  color: #f55;
}

/*
.svg--fb,
.svg--yt,
.svg--tw,
.svg--tg{

}*/
/*@import "blocks/text";
@import "blocks/dropdown";
@import "blocks/navigation";
@import "blocks/svg";
@import "blocks/btn";
@import "blocks/profile-page";
@import "blocks/menu";
@import "blocks/form";
@import "blocks/field";
@import "blocks/text-input";
@import "blocks/area-input";
@import "blocks/select-input";
@import "blocks/image-input";
@import "blocks/checkbox-input";
@import "blocks/password-input";
@import "blocks/file-input";
@import "blocks/soc";
@import "blocks/modal";
@import "blocks/noty";
@import "blocks/post-preview";
@import "blocks/header";
@import "blocks/footer";
@import "blocks/page";
@import "blocks/post";
@import "blocks/rating";
@import "blocks/side-posts";
@import "blocks/side-cats";
@import "blocks/comments";
@import "blocks/comment";
@import "blocks/ya-share";
@import "blocks/sidebar-call";
@import "blocks/post-block";
@import "blocks/contenteditable";
@import "blocks/blockquote";
@import "blocks/cookies";
@import "blocks/spoiler";
@import "blocks/reference";*/
/****************************************************************
 * Specific blocks
 ****************************************************************/
/****************************************************************
 * Layout
 ****************************************************************/
.page {
  /*  width: 100%;
  height: 100vh;

  > .swiper-wrapper > .swiper-slide{
    height: auto;
  }*/
}

/**********************************************************************************
 * ---------------Типовые секции с тайтлами и претайтлами и т.п.----------------- *
 *********************************************************************************/
.section_def {
  padding-bottom: 60px;
  /*------------------------------[ pretitle ]--------------------------------*/
  /*------------------------------[ title ]--------------------------------*/
  /*------------------------------[ list ]--------------------------------*/
  /*------------------------------[ text ]--------------------------------*/
}

.section_def .section__pretitle {
  font: bold 11px/16px "Rounded Mplus 1c";
  text-transform: uppercase;
  color: #757575;
  margin-bottom: 15px;
}

.section_def .section__pretitle_red {
  color: #FF003B;
}

.section_def .section__title {
  font: 900 28px/36px "Rounded Mplus 1c";
  color: #191919;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.section_def .section__title_h1 {
  font: 900 32px/36px 'Rounded Mplus 1c';
}

.section_def .section__list-title {
  font: 900 18px/24px 'Rounded Mplus 1c';
  margin-bottom: 15px;
}

.section_def .section__text {
  font: normal 14px/24px Roboto;
  margin-bottom: 30px;
}

.section_half .section__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.section_half_swap .section__inner {
  flex-direction: row-reverse;
}

/**********************************************************************************
 * ---------------------------Специфические стили секций------------------------- *
 *********************************************************************************/
/*****************************************************************
 * Секция "Проектируем структуру и пишем тексты."
 ****************************************************************/
.section_id_first {
  padding-bottom: 120px;
}

.section_id_first .section__info {
  max-width: 500px;
}

.section_id_first .section__button {
  margin-top: 35px;
}

.section_id_first .section__blockquote {
  max-width: 361px;
}

/*****************************************************************
 * Секция "Проектируем структуру и пишем тексты."
 ****************************************************************/
.section_id_structure_and_texts {
  padding-bottom: 151px;
}

.section_id_structure_and_texts .section__info {
  max-width: 506px;
  margin-right: 64px;
}

/*****************************************************************
 * Секция "Создаём визуальную ассоциацию сайта и компании"
 ****************************************************************/
.section_id_prepare-design-layout {
  padding-bottom: 118px;
}

.section_id_prepare-design-layout .section__info {
  max-width: 548px;
}

.section_id_prepare-design-layout .section__pic {
  margin-right: 100px;
}

/*****************************************************************
 * Секция "Используем WordPress"
 ****************************************************************/
.section_id_wordpress {
  padding-bottom: 142px;
}

.section_id_wordpress .section__info {
  max-width: 570px;
}

/*****************************************************************
 * Секция "Настраиваем рекламу и привлекаем покупателей."
 ****************************************************************/
.section_id_direct {
  padding-bottom: 102px;
}

.section_id_direct .section__info {
  max-width: 507px;
}

/*****************************************************************
 * Секция "Чем с нами удобно?"
 ****************************************************************/
.section_id_advantages-slider {
  background: linear-gradient(to bottom, #FFFAF4, #fff);
  padding-bottom: 125px;
}

.section_id_advantages-slider .section__info {
  max-width: 548px;
}

.section_id_advantages-slider .section__title {
  margin-top: 64px;
  margin-bottom: 60px;
}

.section_id_advantages-slider .section__slider {
  height: 350px;
  overflow: hidden;
}

/*****************************************************************
 * Секция "Сколько стоит?"
 ****************************************************************/
.section_id_price {
  background: #fff;
  padding-bottom: 103px;
}

.section_id_price .section__inner {
  justify-content: flex-start;
}

.section_id_price .section__info {
  width: 50%;
}

.section_id_price .section__price-card {
  padding-left: 40px;
  width: 50%;
}

.section_id_price .section__blockquote {
  padding: 0 60px 88px 142px;
  background: url(../images/man-face.svg) left bottom no-repeat;
}

/*****************************************************************
 * Секция "Вы можете заказать свой лендинг прямо сейчас!"
 ****************************************************************/
.section_id_order-call {
  background: #fff;
  padding-bottom: 120px;
}

.section_id_order-call .section__title {
  font: 900 28px/36px 'Rounded Mplus 1c';
}

.section_id_order-call .section__inner {
  padding: 0 100px;
  position: relative;
}

.section_id_order-call .section__box {
  background: #FFEFE1;
  text-align: center;
  padding: 73px 251px;
  border-radius: 20px;
}

.section_id_order-call .section__box:before {
  content: '';
  background: url(../images/man_1.svg) no-repeat;
  width: 370px;
  height: 333px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.section_id_order-call .section__box:after {
  content: '';
  background: url(../images/woman_2.svg);
  width: 370px;
  height: 333px;
  position: absolute;
  right: 0;
  bottom: 0;
}

/*****************************************************************
 * Секция "Оцените наши работы"
 ****************************************************************/
.section_id_portfolio {
  text-align: center;
  background: #fff;
  padding-bottom: 120px;
}

.section_id_portfolio .section__sliders {
  position: relative;
  padding-bottom: 50px;
  padding-right: 80px;
}

.section_id_portfolio .section__slider-mobile {
  position: absolute;
  right: 100px;
  bottom: 0;
  z-index: 10;
}

.section_id_portfolio .section__sliders-pagination {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 50px;
}

/*****************************************************************
 * Секция "Форма заказа"
 ****************************************************************/
.section_id_feedback {
  background: linear-gradient(to bottom, #fff 0%, #FFFAF4 350px);
  padding-bottom: 120px;
}

.section_id_feedback .section__title {
  text-transform: none;
  font: 900 60px/70px Roboto;
  margin-bottom: 60px;
}

.section_id_feedback .section__form {
  max-width: 870px;
}

/*****************************************************************
 * Секция "Контакты"
 ****************************************************************/
.section_id_contacts {
  padding-bottom: 60px;
}

.section_id_contacts .section__inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.section_id_contacts .section__col {
  max-width: 237px;
}

.section_id_contacts .section__city {
  font: 900 20px/28px 'Rounded Mplus 1c';
  margin-bottom: 30px;
}

.section_id_contacts .section__address {
  font: normal 14px/18px Roboto;
  margin-bottom: 15px;
  min-height: 35px;
}

.section_id_contacts .section__phone {
  font: bold 14px/28px Roboto;
}

/*****************************************************************
 * Секция "Карта"
 ****************************************************************/
.section_map {
  overflow: hidden;
}

/*****************************************************************
 * Responsive
 ****************************************************************/
@media (max-width: 1130px) {
  .section_half .section__inner {
    flex-direction: column;
  }
  .section_half .section__info {
    width: 100%;
    max-width: 570px;
    padding-bottom: 50px;
    margin-right: 0;
  }
  .section_half .section__pic {
    width: 100%;
    margin-right: 0;
    display: flex;
    justify-content: center;
  }
  .section_def {
    padding-bottom: 100px;
  }
  .section_id_advantages-slider .section__info {
    padding-bottom: 0;
  }
  .section_id_price .section__price-card {
    display: flex;
    justify-content: center;
    padding-left: 0;
    width: 100%;
  }
  .section_id_order-call .section__inner {
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }
  .section_id_order-call .section__box {
    padding: 60px;
    max-width: 470px;
  }
  .section_id_order-call .section__box:before, .section_id_order-call .section__box:after {
    display: none;
  }
  .section_id_feedback .section__title {
    font: 900 50px/58px Roboto;
  }
}

@media (max-width: 850px) {
  .section_id_contacts .section__inner {
    flex-direction: column;
  }
  .section_id_contacts .section__col {
    margin-bottom: 40px;
  }
  .section_id_contacts .section__col:last-child {
    margin-bottom: 0;
  }
  .section_def {
    padding-bottom: 80px;
  }
  .section_id_feedback .section__title {
    font: 900 40px/47px Roboto;
    margin-bottom: 50px;
  }
}

@media (max-width: 600px) {
  .section_id_feedback .section__title {
    font: 900 30px/37px Roboto;
    margin-bottom: 35px;
  }
  .section_def {
    padding-bottom: 60px;
  }
  .section_id_advantages-slider .section__title {
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .section_def .section__title {
    font: 900 22px/30px "Rounded Mplus 1c";
  }
  .section_id_feedback .section__title {
    font: 900 20px/25px Roboto;
    margin-bottom: 30px;
  }
  .section_def {
    padding-bottom: 50px;
  }
  .section_id_price .section__blockquote {
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    background: transparent;
  }
  .section_id_advantages-slider .section__slider {
    height: 470px;
  }
}

.header {
  padding-top: 20px;
  margin-bottom: 47px;
}

.header__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__logo {
  margin-right: 90px;
  flex-shrink: 0;
}

@media (max-width: 670px) {
  .header__inner {
    flex-direction: column;
  }
  .header__logo {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
