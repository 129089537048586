.check-input{
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
}

.check-input__input{
  position: absolute;
  left: -100px;
  width: 0;
  height: 0;
  opacity: 0;
}

.check-input__label{
  font: bold 14px/24px Roboto;
  vertical-align: middle;
}

.check-input__label:before{
  content: '';
  display: inline-flex;
  width: 20px;
  height: 20px;
  border: 1px solid #EFE6DB;
  background: #fff;
  margin-right: 15px;
  align-items: center;
  vertical-align: middle;
}

.check-input__label:after{
  content: '';
  position: absolute;
  left: 0;
  top: 1px;
  width: 20px;
  height: 20px;
  background: url(../images/check.svg) center center no-repeat;
  background-size: 14px 12px;
  opacity: 0;
  transform: rotate(45deg) scale(0.7);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}


.check-input__input:checked+.check-input__label:after{
  opacity: 1;
  transform: rotate(0) scale(1);
}

.form__field_error .check-input__label:before{
  border-color: #F194B9;
}


