$browser-context: 16;
$background: rgb(55, 55, 55);
$primary: rgb(230, 230, 230);

@mixin transition($s) {
  transition: all ease-in #{"0." + $s}s;
}

@mixin boxShadow($s, $inset: "") {
  box-shadow: #{$inset} 0 rem($s * 4.5 - 3.5) rem($s * 8.75 - 5.75) rgba($background, $s * 0.045 + 0.075), 0 rem($s * 3.5 - 2.5) rem($s * 2.5 - 0.5) rgba($background, $s * -0.005 + 0.245);
}

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels / $context}rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

@keyframes button_animation {
  to {
    transform: scale(1);
  }
}
