.price-card{
  padding: 90px 60px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 100%;
  max-width: 470px;
  font: normal 14px/28px Roboto;
  background: $light-yellow;
}

.price-card__title{
  font: 900 20px/28px 'Rounded Mplus 1c';
  margin-bottom: 30px;
}

.price-card__price{
  font: 900 40px/40px 'Rounded Mplus 1c';
  margin-bottom: 5px;
}

.price-card__time{
  margin-top: 60px;
}

.price-card__time-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.price-card__time-key{
  font: normal 14px/28px Roboto;
  text-align: left;
}

.price-card__time-value{
  font: bold 14px/28px Roboto;
  text-align: right;
  margin-left: 20px;
}

.price-card__time-row_total{
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #DED9D4;

  .price-card__time-key{
    font-weight: bold;
    color: $red-text;
  }
}

.price-card__adstime{
  margin-top: 30px;
  background: #FFEFE1 no-repeat padding-box;
  border-radius: 10px;
  padding: 15px 20px;
}

.price-card__adstime-title{
  font: 900 16px/28px 'Rounded Mplus 1c';
  margin-bottom: 10px;
}

.price-card__adstime-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.price-card__adstime-label{
  font: normal 14px/18px Roboto;
  text-align: left;
}

.price-card__adstime-key{
  font: normal 14px/18px Roboto;
  text-align: left;
}

.price-card__adstime-value{
  font: bold 14px/18px Roboto;
  text-align: right;
  margin-left: 20px;
}



@media (max-width: 480px) {
  .price-card{
    padding: 40px 30px;
  }

  .price-card__price{
    font: 900 28px/28px 'Rounded Mplus 1c';
  }
}